import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import NavBar from "../../../components/NavBar";
import instance from '../../../globals/axios';
import ProfileHeader from '../../../components/ProfileHeader.js';
import CustomAccordian from '../../../components/CustomAccordian.js';
import DocumentBox from '../../../components/DocumentBox.js';

export default function DocumentLocker({ showTitle, showImage }) {
  const [documentData, setDocumentData] = useState({});
  const [userFiles, setUserFiles] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [experienceData, setExperienceData] = useState({});
  const [loading, setLoading] = useState(true);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    instance.get('document-locker/')
      .then(response => {
        setDocumentData(response.data);
      })
      .catch(error => {
        console.error('Error fetching document data:', error);
      });
  }, []);

  useEffect(() => {
    instance.get('upload-experience-details/')
      .then(response => setUserFiles(response.data))
      .catch(error => console.error('Error fetching additional data:', error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    instance.get('educational-details/')
      .then(response => setEducationData(response.data.data))
      .catch(error => console.error('Error fetching educational data:', error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    instance.get('experience-details/')
      .then(response => setExperienceData(response.data))
      .catch(error => console.error('Error fetching experience data:', error))
      .finally(() => setLoading(false));
  }, []);

  const handleDocumentClick = (url) => {
    window.open(url, "_blank");
  };

  const handleEditClick = (url) => {
    console.log("Edit clicked for:", url);
  };

  const handleDeleteClick = (url) => {
    console.log("Delete clicked for:", url);
  };

  return (
    <>
      <Container style={showTitle || showTitle === undefined ? { marginTop: '30px' } : null}>
        {!showImage && <ProfileHeader showUploadIcon={false} />}

        {(showTitle === undefined || showTitle) && (
          <Typography variant="h6" sx={{ fontWeight: "600" }}>Document Locker</Typography>
        )}

        <Grid>
        {documentData.languages && documentData.languages.length > 0 && (
  <CustomAccordian title="Languages">
    {documentData.languages.map((language) => (
      language.file && ( // Only render DocumentBox if language.file is not null
        <DocumentBox
          key={language.id}
          fileName={language.languageName}
          documentUrl={language.file}
          onClick={handleDocumentClick}
          showEdit={false}
          showDelete={false}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
        />
      )
    ))}
  </CustomAccordian>
)}


{educationData && educationData.length > 0 && (
  <CustomAccordian title="Educational">
    {educationData.map((educationEntry) => (
      <React.Fragment key={educationEntry.id}>
        {/* Display uploaded files */}
        {educationEntry.uploaded_files && educationEntry.uploaded_files.map((file) => (
          file.file !== "https://iveri.s3.amazonaws.com/False" && ( // Only render if file URL is valid
            <DocumentBox
              key={file.uploaded_at}
              fileName={file.file_name}
              documentUrl={file.file}
              onClick={handleDocumentClick}
              showEdit={false}
              showDelete={false}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
            />
          )
        ))}
        
        {/* Display other educational documents */}
        {educationEntry.awards_file && educationEntry.awards_file !== "https://iveri.s3.amazonaws.com/False" && (
          <DocumentBox
            fileName={`${educationEntry.institute_name || ''} Internship Document`}
            documentUrl={educationEntry.awards_file}
            onClick={handleDocumentClick}
            showEdit={false}
            showDelete={false}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
          />
        )}
        
        {educationEntry.extra_curricular_file && educationEntry.extra_curricular_file !== "https://iveri.s3.amazonaws.com/False" && (
          <DocumentBox
            fileName={`${educationEntry.institute_name || ''} Project Document`}
            documentUrl={educationEntry.extra_curricular_file}
            onClick={handleDocumentClick}
            showEdit={false}
            showDelete={false}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
          />
        )}
        
        {educationEntry.educational_file && educationEntry.educational_file !== "https://iveri.s3.amazonaws.com/False" && (
          <DocumentBox
            fileName={`${educationEntry.institute_name || ''} Educational Document`}
            documentUrl={educationEntry.educational_file}
            onClick={handleDocumentClick}
            showEdit={false}
            showDelete={false}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
          />
        )}
      </React.Fragment>
    ))}
  </CustomAccordian>
)}


          {experienceData && experienceData.length > 0 && (
            <CustomAccordian title="Professional Details">
              {experienceData.map((experienceEntry) => (
                <React.Fragment key={experienceEntry.id}>
                  {userFiles
                    .filter(file => file.experience_details === experienceEntry.id)
                    .map(file => (
                      <DocumentBox
                        key={file.id}
                        fileName={file.file_name}
                        documentUrl={file.file}
                        onClick={handleDocumentClick}
                        showEdit={false}
                        showDelete={false}
                        onEditClick={handleEditClick}
                        onDeleteClick={handleDeleteClick}
                      />
                    ))}
                </React.Fragment>
              ))}
            </CustomAccordian>
          )}

{documentData.skills && documentData.skills.length > 0 && (
  <CustomAccordian title="Skills">
    {documentData.skills.map((skill) => (
      skill.file_uploaded && ( // Only render DocumentBox if file_uploaded is not null
        <DocumentBox
          key={skill.id}
          fileName={`${skill.skill}.pdf`}
          documentUrl={skill.file_uploaded}
          onClick={handleDocumentClick}
          showEdit={false}
          showDelete={false}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
        />
      )
    ))}
  </CustomAccordian>
)}



{(documentData.diff_abled_file && documentData.diff_abled_file !== "https://iveri.s3.amazonaws.com/False") ||
(documentData.visa_file && documentData.visa_file !== "https://iveri.s3.amazonaws.com/False") ? (
  <CustomAccordian title="Additional Data">
    {documentData.diff_abled_file && documentData.diff_abled_file !== "https://iveri.s3.amazonaws.com/False" && (
      <DocumentBox
        fileName="Differentially-abled File"
        documentUrl={documentData.diff_abled_file}
        onClick={handleDocumentClick}
      />
    )}
    {documentData.visa_file && documentData.visa_file !== "https://iveri.s3.amazonaws.com/False" && (
      <DocumentBox
        fileName="Visa File"
        documentUrl={documentData.visa_file}
        onClick={handleDocumentClick}
      />
    )}
  </CustomAccordian>
) : null}



        </Grid>
      </Container>
    </>
  );
}