import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { MdVisibility } from 'react-icons/md';
import { IconButton } from "@mui/material";
import instance from "../../globals/axios.js";
import DetailLabel from "../../components/Timeline/Timelinelabel.js";
import { styled } from '@mui/system';

const CustomCard = styled(Card)({
  boxShadow: 'none !important',
  padding: '10px !important',
  margin: '10px !important',
  backgroundColor: '#F8F9F9 !important',
  border: 'none !important',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const EducationTimeline = () => {
  const [educationData, setEducationData] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [expandedGaps, setExpandedGaps] = useState({});

  useEffect(() => {
    instance.get('educational-details/')
      .then(response => {
        const sortedData = response.data.data.sort((a, b) => {
          const endDateA = new Date(a.end_date);
          const endDateB = new Date(b.end_date);
          return endDateB - endDateA; // Sort descending
        });
        setEducationData(sortedData);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  const handleExpandClick = (id) => {
    setExpanded(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleGapExpandClick = (gapId) => {
    setExpandedGaps(prevState => ({
      ...prevState,
      [gapId]: !prevState[gapId]
    }));
  };

  const calculateGap = (endDate, nextStartDate) => {
    if (!endDate || !nextStartDate) return null;
    
    const end = new Date(endDate);
    const start = new Date(nextStartDate);
    
    // If the next start date is after the end date, there's no gap
    if (start > end) return null;
    
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    // Consider gaps longer than 30 days
    if (diffDays <= 30) return null;
    
    return {
      startDate: start,
      endDate: end
    };
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
  };

  const renderTimelineItems = () => {
    if (!Array.isArray(educationData) || educationData.length === 0) {
      return <Typography>No education data available</Typography>;
    }

    const items = [];

    educationData.forEach((detail, index) => {
      // Add education item
      items.push(
        <TimelineItem key={`edu-${index}`}>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                backgroundColor: 'white',
                cursor: 'pointer',
                border: "2px black solid",
                padding: "0px !important"
              }}
              onClick={() => handleExpandClick(detail.id)}
            >
              {expanded[detail.id] ? (
                <RemoveIcon sx={{ color: 'black', fontSize: "15px !important" }} />
              ) : (
                <AddIcon sx={{ color: 'black', fontSize: "15px !important" }} />
              )}
            </TimelineDot>
            <TimelineConnector sx={{ backgroundColor: "black" }} />
          </TimelineSeparator>

          <TimelineContent 
            sx={{
              margin: "2 !important",
              padding: "2 !important",
              maxWidth: '100%',
              overflowX: 'auto',
            }}
          >
            <Paper
              sx={{
                p: "2 !important",
                backgroundColor: '#F8F9F9 !important',
                boxShadow: 'none !important',
                border: 'none !important',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important" }}>
                {`${new Date(detail.start_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })} - ${new Date(detail.end_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}`}
              </Typography>

              {/* Show degree if available, otherwise show type */}
              <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important" }}>
                {detail.degree || detail.type}
              </Typography>

              {detail.institute_name && (
                <Typography sx={{ fontSize: "15px !important", fontWeight: "550 !important" }}>
                  {detail.institute_name}
                </Typography>
              )}

              <Collapse in={expanded[detail.id]} timeout="auto" unmountOnExit>
                <CardContent sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {detail.institute_name && <DetailLabel label="College Name" value={detail.institute_name} />}
                  {detail.specialization && <DetailLabel label="Specialization" value={detail.specialization} />}
                  {detail.board && <DetailLabel label="Board" value={detail.board} />}
                  {detail.score && <DetailLabel label="Score" value={detail.score} />}
                  {detail.total_score && <DetailLabel label="Total Score" value={detail.total_score} />}
                  {detail.score_unit && <DetailLabel label="Score Unit" value={detail.score_unit} />}
                  {detail.certificate_name && <DetailLabel label="Certificates" value={detail.certificate_name} />}

                  {/* Projects section - only render if project has data */}
                  {detail.projects && detail.projects.map(project => (
                    project.project_title || project.project_desc || project.project_link ? (
                      <Box key={project.id}>
                        {project.project_title && <DetailLabel label="Project Title" value={project.project_title} />}
                        {project.project_desc && <DetailLabel label="Project Description" value={project.project_desc} />}
                        {project.project_link && <DetailLabel label="Project Link" value={project.project_link} />}
                      </Box>
                    ) : null
                  ))}

                  {/* Internships section - only render if internship has data */}
                  {detail.internships && detail.internships.map(internship => (
                    internship.internship_company || internship.internship_role || internship.internship_duration_from || internship.internship_duration_to ? (
                      <Box key={internship.id}>
                        {internship.internship_company && <DetailLabel label="Internship Company" value={internship.internship_company} />}
                        {internship.internship_role && <DetailLabel label="Internship Role" value={internship.internship_role} />}
                        {internship.internship_duration_from && <DetailLabel label="From" value={new Date(internship.internship_duration_from).toLocaleDateString()} />}
                        {internship.internship_duration_to && <DetailLabel label="To" value={new Date(internship.internship_duration_to).toLocaleDateString()} />}
                      </Box>
                    ) : null
                  ))}

                  {/* Files section - only render valid files */}
                  {detail.uploaded_files && detail.uploaded_files.length > 0 && detail.uploaded_files.map((file) => (
                    file.file && file.file !== "https://iveri.s3.amazonaws.com/False" && (

                      <Box 
                      key={file.uploaded_files}
                      display="flex !important" 
                      alignItems="center !important" 
                      justifyContent="space-between !important" 
                      border={1} 
                      borderRadius={2} 
                      sx={{ padding: "2px 6px !important", mt: 2, mb: 2 }}
                      style={{ cursor: 'pointer' }}
                      onClick={() => window.open(file.file, "_blank")}
                    >
                      <Typography variant="body1">
                        {file.file_name}
                      </Typography>
                      <IconButton onClick={(e) => {
                        e.stopPropagation();
                        window.open(file.file, "_blank");
                      }}>
                        <MdVisibility />
                      </IconButton>
                    </Box>
                    )
                  ))}
{['awards_file', 'extra_curricular_file', 'educational_file'].map((fileType) => {
  const fileUrl = detail[fileType];
  if (fileUrl && fileUrl !== "https://iveri.s3.amazonaws.com/False") {
    let displayName = '';
    
    switch(fileType) {
      case 'awards_file':
        displayName = `${detail.institute_name} Internship Document`;
        break;
      case 'extra_curricular_file':
        displayName = `${detail.institute_name} Project Document`;
        break;
      case 'educational_file':
        displayName = `${detail.institute_name} Education Document`;
        break;
      default:
        displayName = fileType.replace('_file', '').replace('_', ' ').toUpperCase();
    }

    return (
      <Box 
        key={fileType}
        display="flex" 
        alignItems="center" 
        justifyContent="space-between" 
        border={1} 
        borderRadius={2} 
        sx={{ padding: "2px 6px", mt: 2, mb: 2 }}
        style={{ cursor: 'pointer' }}
        onClick={() => window.open(fileUrl, "_blank")}
      >
        <Typography variant="body1">
          {displayName}
        </Typography>
        <IconButton onClick={(e) => {
          e.stopPropagation();
          window.open(fileUrl, "_blank");
        }}>
          <MdVisibility />
        </IconButton>
      </Box>
    );
  }
  return null;
})}
                </CardContent>
              </Collapse>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      );

      // Check for gap with next education item
      if (index < educationData.length - 1) {
        const currentEndDate = detail.end_date;
        const nextStartDate = educationData[index + 1].start_date;
        const gap = calculateGap(currentEndDate, nextStartDate);

        if (gap) {
          const gapId = `gap-${index}`;
          items.push(
            <TimelineItem key={gapId}>
              <TimelineSeparator>
                <TimelineDot
                  sx={{ 
                    backgroundColor: 'white', 
                    cursor: 'pointer', 
                    border: "2px black solid", 
                    padding: "0px !important" 
                  }}
                  onClick={() => handleGapExpandClick(gapId)}
                >
                  {expandedGaps[gapId] ? (
                    <RemoveIcon sx={{ color: 'black', fontSize: "15px !important" }} />
                  ) : (
                    <AddIcon sx={{ color: 'black', fontSize: "15px !important" }} />
                  )}
                </TimelineDot>
                <TimelineConnector sx={{ backgroundColor: "black" }} />
              </TimelineSeparator>
              <TimelineContent sx={{ margin: "2 !important", padding: "2 !important" }}>
                <Paper
                  sx={{
                    p: "2 !important",
                    backgroundColor: '#F8F9F9 !important',
                    boxShadow: 'none !important',
                    border: 'none !important',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Typography sx={{ 
                    color: '#FF0000',
                    fontSize: "18px !important", 
                    fontWeight: "600 !important",
                    padding: "8px !important"
                  }}>
                    {`${formatDate(gap.startDate)} - ${formatDate(gap.endDate)} GAP`}
                  </Typography>
                  
                  <Collapse in={expandedGaps[gapId]} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Typography>
                        Gap period between educational programs
                      </Typography>
                    </CardContent>
                  </Collapse>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          );
        }
      }
    });

    return items;
  };

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: "0 !important",
          padding: "0 !important",
        },
        padding: "0 !important",
      }}
    >
      {renderTimelineItems()}
    </Timeline>
  );
};

export default EducationTimeline;