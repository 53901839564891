import React, { useState, useEffect } from "react";
import { Button, Modal, Box, TextField, Typography } from "@mui/material";
import CustomButton from "../Button/CustomButtom";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SocialMedialPopup = ({ onAdd, editingLink, onCancel }) => {
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (editingLink) {
      setName(editingLink.name || '');
      setUrl(editingLink.url || '');
      setIsOpen(true);
    }
  }, [editingLink]);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    setName('');
    setUrl('');
    onCancel?.();
  };

  const handleSubmit = () => {
    onAdd(name, url);
    handleClose();
  };

  return (
    <>
      <CustomButton onClick={handleOpen} fontSize={"14px"}>
        {editingLink ? "Edit Social Media" : "+Add New"}
      </CustomButton>

      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Typography variant="h6">
            {editingLink ? "Edit Social Media" : "Add Social Media"}
          </Typography>
          <TextField
            label="Social Media Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ marginTop: "10px" }}
          />
          <TextField
            label="URL"
            variant="outlined"
            fullWidth
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            sx={{ marginTop: "10px" }}
          />
          <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
            <CustomButton onClick={handleSubmit}>
              {editingLink ? "Save" : "Add"}
            </CustomButton>
            <CustomButton onClick={handleClose}>
              Cancel
            </CustomButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SocialMedialPopup;
