import React, { useState, useEffect } from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import ProfileHeader from "../../../components/ProfileHeader";
import instance from "../../../globals/axios";
import { useHistory, Link } from "react-router-dom";

import AddSectionPlaceholder from "../../../components/Button/AddSectionPlaceholder";
import ConfirmDeleteDialog from "../../../components/Popups/ConfirmDeleteDialog";
import ProfileCard from "../../../components/Card/ProfileDetails";
import GenericTable from "../../../components/Table/GenericTable";
import {
  FaLinkedin,
  FaBehance,
  FaGithub,
  FaFacebook,
  FaUser,
  FaCheck,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import Sidebar from "../../../components/Sidebar";


const PersonalInfoViewPage = () => {
  const history = useHistory();
  const [basicInfoDetails, setBasicInfoDetails] = useState({});
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const refetchData = () => {
    instance
      .get("basic-info/")
      .then((response) => {
        const data = response.data;
        setBasicInfoDetails(data);
        setError("");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again.");
        setBasicInfoDetails({});
      });
  };

  const handleEditButton = (id) => {
    history.push(`/personal-details-infos/${id}`);
  };

  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setOpenDialog(true);
  };

  const handleDelete = () => {
    instance
      .delete(`educational-details/${selectedId}/`)
      .then(() => {
        refetchData();
        setOpenDialog(false);
      })
      .catch((error) => {
        console.error("Error deleting education:", error);
        setError("Error deleting education. Please try again.");
        setOpenDialog(false);
      });
  };

  const socialMediaLinks = {
    linkedin: {
      url: basicInfoDetails.linkedin,
      icon: <FaLinkedin style={{ fontSize: 30, color: '#0077b5' }} />
    },
    github: {
      url: basicInfoDetails.github,
      icon: <FaGithub style={{ fontSize: 30, color: '#333' }} />
    },
    facebook: {
      url: basicInfoDetails.facebook,
      icon: <FaFacebook style={{ fontSize: 30, color: '#4267B2' }} />
    },
    behance: {
      url: basicInfoDetails.behance,
      icon: <FaBehance style={{ fontSize: 30, color: '#1769ff' }} />
    },
    other: {
      url: basicInfoDetails.other,
      icon: <FaUser style={{ fontSize: 30, color: '#262262' }} /> // Default icon for other platforms
    }
  };


  useEffect(() => {
    refetchData();
  }, []);

  // Columns for the Family Details table
  const familyColumns = ["Relationship", "Name", "Age", "Occupation"];

  // Function to render rows for family members
  const renderFamilyRow = (familyMember) => [
    familyMember.relationship || "N/A",
    `${familyMember.name} ` || "N/A",
    familyMember.age || "N/A",
    familyMember.occupation || "N/A",
  ];

  return (
    <>
    <Sidebar />
    <Container maxWidth="xs" style={{ marginTop: "30px" }}>
      
      <ProfileHeader showUploadIcon={false}  />
      {error && <Typography color="error">{error}</Typography>}

      {!basicInfoDetails.email ? ( // Check if data is missing or not
        <>
          <Typography variant="h6" sx={{ fontWeight: "600" }} mt={2}>
            Personal Information
          </Typography>
          <AddSectionPlaceholder
            message="education"
            buttonText="+ Add Personal Information"
            link="/edit-education-details"
            onClick={() => history.push("/personal-details-info")}
          />
        </>
      ) : (
        <Box>
          {/* Pass object data directly to ProfileCard */}
          <ProfileCard
            key={basicInfoDetails.email} // Use a unique identifier like email or id
            title="Personal Information"
            data={{
              Name: 
              `${basicInfoDetails.first_name} ${
                basicInfoDetails.middle_name && basicInfoDetails.middle_name !== "null" 
                  ? basicInfoDetails.middle_name + ' ' 
                  : ''
              }${basicInfoDetails.last_name}`,
              Email: basicInfoDetails.email,
              Phone: basicInfoDetails.mobile_number,
            }}
            onEdit={() => handleEditButton(basicInfoDetails.id)}
            onDelete={() => handleDeleteClick(basicInfoDetails.id)}
          />

          {/* Family Details Table */}
          {basicInfoDetails.family_members && basicInfoDetails.family_members.length > 0 && (
            <>
              <Typography variant="h6" sx={{ fontWeight: "550" }} mt={2}>
                Family Details
              </Typography>
              <GenericTable
                columns={familyColumns}
                data={basicInfoDetails.family_members}
                renderRow={renderFamilyRow}
              />
            </>
          )}
        </Box>
      )}
              <Box display="flex" alignItems="center" mb={0.8}>
          <Typography variant="h6" sx={{ mt: 1.5, fontWeight: "550 !important" }}>
            Social Media Links
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" mb={0.8} mt={1}>
      {basicInfoDetails.social_links && basicInfoDetails.social_links.length > 0 ? (
        <Box display="flex" flexDirection="column" gap={2}>
          {basicInfoDetails.social_links.map((link, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Typography 
                component="span" 
                sx={{ 
                  marginRight: 1,
                  color: "#262262 !important",
                  fontSize: "16px !important",
                  fontWeight: "510 !important",
                }}
              >
                {link.name} -
              </Typography>
              <Typography
                component="a"
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  wordBreak: 'break-all',
                  color: '#1976d2',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {link.url}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Box>No social links available</Box>
      )}
    </Box>
        <Button
            onClick={() => history.push("/personal-details-info")}
            mt={2}
            sx={{
              border: "1px solid #666671",
              textTransform: "none",
              color: "#666671",
              fontSize: "24px",
            }}
            fullWidth>
            + Add Personal Details
          </Button>

      <ConfirmDeleteDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        onDelete={handleDelete}
        title={"Education Detail"}
      />
    </Container>
    </>
  );
};

export default PersonalInfoViewPage;
