import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Checkbox,
  Typography,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import instance from "../../../globals/axios.js";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Sidebar from "../../../components/Sidebar.js";
import ProfileHeader from "../../../components/ProfileHeader.js";
import DocumentBox from "../../../components/DocumentBox.js";
import { useParams } from "react-router-dom";
import CustomButton from "../../../components/Button/CustomButtom.js";

const DropzoneContainer = styled("div")({
  border: "2px dashed #ccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  marginBottom: "20px",
});

export default function AdditionalInfo() {
  const [formData, setFormData] = useState({
    disability: "",
    visa_type: "",
    visa_country: "",
    // visa_valid_till: "",
  });

  const [has_visa_card, setHas_visa_card] = useState(false);
  const [is_differently_abled, setIs_differently_abled] = useState(false);

  const { id } = useParams();

  const [visaFileUrl, setVisaFileUrl] = useState(null);
  const [diffAbledFileUrl, setDiffAbledFileUrl] = useState(null);

  const [visaFileName, setVisaFileName] = useState("");
  const [diffAbledFileName, setDiffAbledFileName] = useState("");

  const getFileNameFromUrl = (url) => {
    if (!url) return "";
    const parts = url.split('/');
    return parts[parts.length - 1];
  };

  const isValidFileUrl = (url) => {
    return url && url !== "https://iveri.s3.amazonaws.com/False";
  };

  useEffect(() => {
    if (id) {
      instance
        .get(`/additional-info/${id}/`)
        .then((response) => {
          const data = response.data.data;
          setFormData({
            disability: data.disability || "",
            visa_type: data.visa_type || "",
            visa_country: data.visa_country || "",
          });

          // Set checkbox values based on fetched data
          setIs_differently_abled(Boolean(data.disability));
          setHas_visa_card(Boolean(data.visa_type));

          if (isValidFileUrl(data.visa_file)) {
            setVisaFileUrl(data.visa_file);
            setVisaFileName(getFileNameFromUrl(data.visa_file));
          }

          if (isValidFileUrl(data.diff_abled_file)) {
            setDiffAbledFileUrl(data.diff_abled_file);
            setDiffAbledFileName(getFileNameFromUrl(data.diff_abled_file));
          }
        })
        .catch((error) => {
          console.error("Error fetching experience details:", error);
        });
    }
  }, [id]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === "is_differently_abled") {
      setIs_differently_abled(checked);
    } else if (name === "has_visa_card") {
      setHas_visa_card(checked);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const [visaFile, setVisaFile] = useState(null);
  const [diffAbledFile, setDiffAbledFile] = useState(null);

  const onDropVisa = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setVisaFile(acceptedFiles[0]);
    }
  };

  const onDropDiffAbled = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setDiffAbledFile(acceptedFiles[0]);
    }
  };

  const { getRootProps: getVisaRootProps, getInputProps: getVisaInputProps } = useDropzone({
    accept: ".pdf, .jpeg, .jpg, .png",
    onDrop: onDropVisa,
  });

  const { getRootProps: getDiffAbledRootProps, getInputProps: getDiffAbledInputProps } =
    useDropzone({
      accept: ".pdf, .jpeg, .jpg, .png",
      onDrop: onDropDiffAbled,
    });

  const handleVisaFileDelete = async () => {
    try {
      await instance.patch(`/additional-info/${id}/`, { visa_file: null });
      setVisaFile(null);
      setVisaFileUrl(null);
    } catch (error) {
      console.error("Error deleting visa file:", error);
    }
  };

  const handleDiffAbledFileDelete = async () => {
    try {
      await instance.patch(`/additional-info/${id}/`, { diff_abled_file: null });
      setDiffAbledFile(null);
      setDiffAbledFileUrl(null);
    } catch (error) {
      console.error("Error deleting disability file:", error);
    }
  };

  const handleDocumentView = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleSaveClick = async () => {
    const sendData = new FormData();
    sendData.append("disability", formData.disability);
    sendData.append("visa_type", formData.visa_type);
    sendData.append("visa_country", formData.visa_country);

    if (visaFile) sendData.append("visa_file", visaFile);

    if (diffAbledFile) sendData.append("diff_abled_file", diffAbledFile);

    console.log([...sendData]);
    const url = id ? `additional-info/${id}/` : "additional-info/";

    const requestMethod = id ? "put" : "post";

    try {
      const response = await instance[requestMethod](url, sendData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Additonal data added Successfully");
      setTimeout(() => {
        window.location = "/additional-viewpage";
      }, 200);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <>
      <Sidebar />
      <Container sx={{ px: 2 }} maxWidth="xs" style={{ marginTop: "30px" }}>
        <ProfileHeader showUploadIcon={false} />
        <Grid xs={10} mx="auto" my={1}>
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Additional Information
          </Typography>
        </Grid>

        {/* Differently Abled Section */}
        <div>
          <Link to="#" onClick={() => setIs_differently_abled(!is_differently_abled)}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <label style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    letterSpacing: "1.5px",
                    color: "rgb(5, 32, 71)",
                    textTransform: "none"
                  }} >Diff. Abled</label>
              </div>
              <div>
                <Checkbox
                  checked={is_differently_abled}
                  onChange={handleCheckboxChange}
                  name="is_differently_abled"
                  color="primary"
                />
              </div>
            </div>
          </Link>

          {is_differently_abled && (
            <Grid my={1} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  name="disability"
                  value={formData.disability}
                  onChange={handleChange}
                  fullWidth
                  sx={{
                    backgroundColor: "rgba(214,214,214, 0.3)",
                    borderRadius: "5px",
                    height: "45px", 
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                      "& input": {
                        padding: "10px 14px", 
                      },
                    },
                  }}
                />
              </Grid>

              {/* File Upload for Disability */}
              <Grid p={4}>
                <Typography>Upload Differently Abled Proof File</Typography>
                <DropzoneContainer {...getDiffAbledRootProps()}>
                  <input {...getDiffAbledInputProps()} />
                  <Typography>Drag and drop a PDF file here, or click to select one.</Typography>
                </DropzoneContainer>
                {(diffAbledFile || (diffAbledFileUrl && isValidFileUrl(diffAbledFileUrl))) && (
                  <DocumentBox
                    fileName={diffAbledFile ? diffAbledFile.name : diffAbledFileName}
                    documentUrl={diffAbledFile ? URL.createObjectURL(diffAbledFile) : diffAbledFileUrl}
                    onDeleteClick={diffAbledFile ? () => setDiffAbledFile(null) : handleDiffAbledFileDelete}
                    onClick={() => handleDocumentView(diffAbledFileUrl)}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </div>

        {/* Visa Section */}
        <div>
          <Link to="#" onClick={() => setHas_visa_card(!has_visa_card)}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <label style={{ fontSize: "20px", fontWeight: "500" }}>Visa</label>
              </div>
              <div>
                <Checkbox
                  checked={has_visa_card}
                  onChange={handleCheckboxChange}
                  name="has_visa_card"
                  color="primary"
                />
              </div>
            </div>
          </Link>

          {has_visa_card && (
            <Grid my={1} container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select name="visa_type" value={formData.visa_type} onChange={handleChange}
                  sx={{
                    backgroundColor: "rgba(214,214,214, 0.3)",
                    height: "45px",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}>
                    <MenuItem value="Work">Work</MenuItem>
                    <MenuItem value="Student">Student</MenuItem>
                    <MenuItem value="Permanent">Permanent</MenuItem>
                    <MenuItem value="Tourist">Tourist</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* File Upload for Visa */}
              <Grid p={4}>
                <Typography>Upload Visa File</Typography>
                <DropzoneContainer {...getVisaRootProps()}>
                  <input {...getVisaInputProps()} />
                  <Typography>Drag and drop a PDF file here, or click to select one.</Typography>
                </DropzoneContainer>
                {(visaFile || (visaFileUrl && isValidFileUrl(visaFileUrl))) && (
                  <DocumentBox
                    fileName={visaFile ? visaFile.name : visaFileName}
                    documentUrl={visaFile ? URL.createObjectURL(visaFile) : visaFileUrl}
                    onDeleteClick={visaFile ? () => setVisaFile(null) : handleVisaFileDelete}
                    onClick={() => handleDocumentView(visaFileUrl)}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </div>

        <CustomButton onClick={handleSaveClick}>SAVE</CustomButton>
      </Container>
    </>
  );
}