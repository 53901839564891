import React, { useState, useEffect } from "react";
import { 
  Container, 
  Typography, 
  IconButton, 
  Box, 
  Grid 
} from "@mui/material";
import ProfileHeader from "../../../components/ProfileHeader.js";
import instance from "../../../globals/axios.js";
import Editbutton from "../../../assets/Icons/Edit.png";
import trashIcon from "../../../assets/Icons/delete.png";
import { useHistory } from "react-router-dom";
import AddSectionPlaceholder from "../../../components/Button/AddSectionPlaceholder";
import DocumentBox from "../../../components/DocumentBox.js";
import Sidebar from "../../../components/Sidebar.js";
import ConfirmDeleteDialog from "../../../components/Popups/ConfirmDeleteDialog";

export default function ViewAdditionalPage() {
  const [additionalInfo, setAdditionalInfo] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [infoToDelete, setInfoToDelete] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchAdditionalInfo();
  }, []);

  const openDocument = (url) => {
    window.open(url, "_blank");
  };

  const fetchAdditionalInfo = () => {
    instance
      .get("additional-info/")
      .then((response) => {
        const infoObject = response.data.data[0];
        if (infoObject) {
          setAdditionalInfo(infoObject);
        } else {
          setAdditionalInfo(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching additional information data:", error);
        setAdditionalInfo(null);
      });
  };

  const handleEditButton = (id) => {
    history.push(`/edit-additional-infos/${id}`);
  };

  const handleDeleteClick = (info) => {
    setInfoToDelete(info);
    setOpenDialog(true);
  };

  const handleDelete = () => {
    instance
      .delete(`/additional-info/${infoToDelete.id}`)
      .then(() => {
        setOpenDialog(false);
        fetchAdditionalInfo();
      })
      .catch((error) => {
        console.error("Error deleting additional information:", error);
        alert("An error occurred while deleting.");
        setOpenDialog(false);
      });
  };

  const handleAddMore = () => {
    history.push("edit-additional-info");
  };

  return (
    <Grid container spacing={2}>
      {/* Sidebar */}
      <Grid item xs={12} md={2}>
        <Sidebar />
      </Grid>

      {/* Main Content */}
      <Grid item xs={12} md={10}>
        <Container maxWidth="md" sx={{ py: 3 }}>
          <ProfileHeader showUploadIcon={false} />
          
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Additional Information
            </Typography>
          </Box>

          {!additionalInfo ? (
            <AddSectionPlaceholder
              message="Additional Information"
              buttonText="+ Add Additional Information"
              link="/edit-additional-info"
              onClick={handleAddMore}
            />
          ) : (
            <Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography variant="h6" sx={{ fontWeight: "600", fontSize: "22px" }}>
                  Additional Information
                </Typography>

                <Box>
                  <IconButton onClick={() => handleEditButton(additionalInfo.id)}>
                    <img
                      src={Editbutton}
                      style={{ width: "24px", height: "24px", marginRight: "12px" }}
                      alt="Edit"
                    />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteClick(additionalInfo)}>
                    <img
                      src={trashIcon}
                      style={{ width: "24px", height: "24px" }}
                      alt="Delete"
                    />
                  </IconButton>
                </Box>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {additionalInfo.disability ? (
                    <Typography variant="subtitle1">
                      <strong>Disability:</strong> {additionalInfo.disability}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1">No disability information available.</Typography>
                  )}
                </Grid>

                {additionalInfo.diff_abled_file && additionalInfo.diff_abled_file !== "https://iveri.s3.amazonaws.com/False" && (
                  <Grid item xs={12}>
                    <DocumentBox
                      fileName={"Disability Certificate.pdf"}
                      documentUrl={additionalInfo.diff_abled_file}
                      onClick={(url) => openDocument(url)}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  {additionalInfo.visa_type ? (
                    <Typography variant="subtitle1">
                      <strong>Visa type:</strong> {additionalInfo.visa_type}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1">No visa information available.</Typography>
                  )}
                </Grid>

                {additionalInfo.visa_file && additionalInfo.visa_file !== "https://iveri.s3.amazonaws.com/False" && (
                  <Grid item xs={12}>
                    <DocumentBox
                      fileName={"Visa Certificate.pdf"}
                      documentUrl={additionalInfo.visa_file}
                      onClick={(url) => openDocument(url)}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          )}

          <ConfirmDeleteDialog
            open={openDialog}
            handleClose={() => setOpenDialog(false)}
            onDelete={handleDelete}
            title={"Additional Information"}
          />
        </Container>
      </Grid>
    </Grid>
  );
}