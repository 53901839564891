import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Radio,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CustomButton from "../../../components/Button/CustomButtom";
import instance from "../../../globals/axios";
import ProfileHeader from "../../../components/ProfileHeader";
import BackButton from "../../../components/Button/BackButton";
import Sidebar from "../../../components/Sidebar";
import DocumentBox from "../../../components/DocumentBox";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { languageOptions } from "../../../Data/Options";

const Lang = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [customLanguage, setCustomLanguage] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const history = useHistory();
  const [proficiency, setProficiency] = useState({
    read: "beginner",
    write: "beginner",
    speak: "beginner",
  });
  const [submittedLanguages, setSubmittedLanguages] = useState([]);
  const { id } = useParams();
  const [existingLanguages, setExistingLanguages] = useState([]);

  const proficiencyLevels = ["beginner", "intermediate", "expert"];

  useEffect(() => {
    if (id) {
      instance
        .get(`language-data/${id}`)
        .then((res) => {
          const data = res.data;
          setSelectedLanguage(data.languageName);
          setProficiency({
            read: data.readingProficiency,
            write: data.writingProficiency,
            speak: data.speakingProficiency,
          });

          if (data.file) {
            // Use the complete S3 URL directly without concatenating base URL
            setUploadedFile({
              name: data.fileName || "uploaded_file",
              type: data.fileType || "application/octet-stream",
              url: data.file  // S3 URL is already complete
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching basic info:", error);
        });
    }
  }, [id]);

  useEffect(() => {
    // Fetch all languages for the user
    instance.get('language-data/')
      .then((res) => {
        setExistingLanguages(res.data);
      })
      .catch((error) => {
        console.error("Error fetching languages:", error);
      });
  }, []);

  const handleProficiencyChange = (skill, level) => {
    setProficiency((prev) => ({
      ...prev,
      [skill]: proficiencyLevels[level],
    }));
  };

  const handleFileUpload = (event) => {
    setUploadedFile(event.target.files[0]);
  };

  const handleLanguageChange = (event) => {
    const value = event.target.value;
    setSelectedLanguage(value);
    if (value !== "Others") {
      setCustomLanguage("");
    }
  };

  const handleCustomLanguageChange = (event) => {
    setCustomLanguage(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const languageName = selectedLanguage === "Others" ? customLanguage : selectedLanguage;

    // Check if language already exists
    const languageExists = existingLanguages.some(
      lang => lang.languageName.toLowerCase() === languageName.toLowerCase()
    );

    if (languageExists && !id) {
      alert("This language has already been added to your profile.");
      return;
    }

    try {
      const languageName = selectedLanguage === "Others" ? customLanguage : selectedLanguage;

      // First, submit the language data
      const languageData = new FormData();
      languageData.append("languageName", languageName);
      languageData.append("readingProficiency", proficiency.read);
      languageData.append("writingProficiency", proficiency.write);
      languageData.append("speakingProficiency", proficiency.speak);
      const requestType = id ? "put" : "post";
      const url = id ? `language-data/${id}/` : "language-data/";

      const languageResponse = await instance[requestType](url, languageData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (languageResponse.status === 201) {
        setExistingLanguages([...existingLanguages, { languageName }]);
        const languageId = languageResponse.data.id;
        if (uploadedFile) {
          const fileData = new FormData();
          fileData.append("file", uploadedFile);
          fileData.append("language_id", languageId);

          const fileResponse = await instance.post("language-file-upload/", fileData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (fileResponse.status === 200) {
            alert("Data and file uploaded successfully.");
          } else {
            console.error("Error uploading file");
          }
        } else {
          alert("Data Saved Successfully without file.");
        }
        setSelectedLanguage("");
        setCustomLanguage(""); // Reset custom language input
        setProficiency({
          read: "beginner",
          write: "beginner",
          speak: "beginner",
        });
        setUploadedFile(null);
      } else {
        console.error("Error saving language data");
      }
      history.push("/language-viewpage");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  console.log("custom", uploadedFile);
  return (
    <>
      <Sidebar />
      <Container style={{ marginTop: "30px" }}>
        <ProfileHeader showUploadIcon={false} />
        <Box sx={{ padding: "16px", marginTop: "3rem" }}>
          <Box sx={{ marginBottom: "24px" }}>
            <Typography style={{ fontSize: "22px", fontWeight: 700 }}>
              Language Proficiency
            </Typography>
            <Typography style={{ fontSize: "18px", fontWeight: 500 }}>Add language</Typography>
          </Box>

          <FormControl fullWidth sx={{ marginBottom: "24px" }}>
            <Select
              value={selectedLanguage}
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                height: "45px",
                borderRadius: "5px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              onChange={handleLanguageChange}>
              {languageOptions.map((language) => (
                <MenuItem key={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedLanguage === "Others" && (
            <FormControl fullWidth sx={{ marginBottom: "24px" }}>
              <TextField
                label="Specify Language"
                value={customLanguage}
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  height: "45px",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                onChange={handleCustomLanguageChange}
              />
            </FormControl>
          )}

          <Box sx={{ marginBottom: "24px" }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <Typography style={{ fontSize: "11px", fontWeight: 600 }}>
                  Language Skill
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontSize: "11px", fontWeight: 600 }}>Beginner</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontSize: "11px", fontWeight: 600 }}>Intermediate</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontSize: "12px", fontWeight: 600 }}>Expert</Typography>
              </Grid>

              {["read", "write", "speak"].map((skill) => (
                <React.Fragment key={skill}>
                  <Grid item xs={3}>
                    <Typography variant="body1" textTransform="capitalize">
                      {skill}
                    </Typography>
                  </Grid>
                  {[0, 1, 2].map((level) => (
                    <Grid item xs={3} key={level}>
                      <Radio
                        checked={proficiency[skill] === proficiencyLevels[level]}
                        onChange={() => handleProficiencyChange(skill, level)}
                        value={level}
                        name={`radio-button-${skill}`}
                        sx={{
                          color: "#262262",
                          "&.Mui-checked": {
                            color: "#262262",
                          },
                          padding: 0,
                        }}
                      />
                    </Grid>
                  ))}
                </React.Fragment>
              ))}
            </Grid>
          </Box>

          <Box sx={{ marginBottom: "24px", textAlign: "center" }}>
            <Typography variant="h6">
              {selectedLanguage === "Others" ? customLanguage : selectedLanguage}
            </Typography>
            <Box
              sx={{
                border: "1px dashed #ccc",
                padding: "16px",
                margin: "16px 0",
                textAlign: "center",
                borderRadius: "4px",
                cursor: "pointer",
                position: "relative",
              }}>
              <input
                type="file"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  cursor: "pointer",
                }}
                onChange={handleFileUpload}
              />
              {uploadedFile ? uploadedFile.name : "Upload file"}
            </Box>

            {uploadedFile && (
              <DocumentBox
                fileName={uploadedFile.name}
                documentUrl={uploadedFile.url || URL.createObjectURL(uploadedFile)}
                onClick={(url) => window.open(url, '_blank', 'noopener,noreferrer')}
                showDelete={true}
                onDeleteClick={() => setUploadedFile(null)}
              />
            )}
          </Box>

          <CustomButton onClick={handleSubmit} variant="contained">
            Submit
          </CustomButton>

          {submittedLanguages.length > 0 && (
            <Box sx={{ marginTop: "24px" }}>
              <Typography variant="h6" gutterBottom>
                Submitted Languages
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Language</TableCell>
                    <TableCell>Reading Proficiency</TableCell>
                    <TableCell>Writing Proficiency</TableCell>
                    <TableCell>Speaking Proficiency</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {submittedLanguages.map((lang) => (
                    <TableRow key={lang.id}>
                      <TableCell>{lang.languageName}</TableCell>
                      <TableCell>{lang.readingProficiency}</TableCell>
                      <TableCell>{lang.writingProficiency}</TableCell>
                      <TableCell>{lang.speakingProficiency}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
};

export default Lang;
