import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Typography,
  Grid,
  TextField,
  Select,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  MenuItem,
  Autocomplete,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";

import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./DatePicker.css";
import { hobbiesArray } from "../../../Data/Options.js";
import TrashIcon from "../../../assets/Icons/delete.png";
import EditIcon from "../../../assets/Icons/Edit.png";
import CustomButton from "../../../components/Button/CustomButtom.js";
import { useHistory } from "react-router-dom";

import { SERVER_BASE_ADDRESS } from "../../../globals/const";
import instance from "../../../globals/axios.js";
import dayjs from "dayjs";
import { FaLinkedin, FaBehance, FaGithub, FaFacebook, FaUser } from "react-icons/fa";
import ProfileHeader from "../../../components/ProfileHeader.js";
import Sidebar from "../../../components/Sidebar.js";
import { useParams } from "react-router-dom";
import ConfirmDeleteDialog from "../../../components/Popups/ConfirmDeleteDialog.js";
import BackButton from "../../../components/Button/BackButton.js";
import SocialMedialPopup from "../../../components/Popups/SocialMediaPopup.js";

export default function PersonalDetailsinfo() {
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const history = useHistory();
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_number: "",
    birth_date: null,
    gender: "",
    relationship_status: "",
    hobbies: [], // Initialize as empty array
    linkedin: "",
    github: "",
    facebook: "",
    behance: "",
    other: "",
  });
  const [newHobby, setNewHobby] = useState("");
  const [familyDetails, setFamilyDetails] = useState([]);
  const [familyId, setFamilyId] = useState(null);
  const [isAddingNewMember, setIsAddingNewMember] = useState(false);
  const [newMember, setNewMember] = useState({});
  const [editingSocialMedia, setEditingSocialMedia] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);

  const updateFormData = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBirthDateChange = (date) => {
    const formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : null;
    setFormData((prevData) => ({
      ...prevData,
      birth_date: formattedDate,
    }));
  };

  const clearBirthDate = () => {
    setFormData((prevData) => ({
      ...prevData,
      birth_date: null,
    }));
  };

  const handleRelationshipChange = (event) => {
    const value = event.target.value;

    setFormData((prevData) => ({
      ...prevData,
      relationship_status: value,
    }));
  };

  const handleSaveFamilyMember = (index) => {
    setFamilyDetails((prevDetails) =>
      prevDetails.map((member, i) => (i === index ? { ...member, isEditing: true } : member))
    );
  };

  const handleFamilyChange = (index, field, value) => {
    const updatedFamilyDetails = [...familyDetails];
    updatedFamilyDetails[index][field] = value;
    setFamilyDetails(updatedFamilyDetails);
  };
  const handleAddFamilyMember = () => {
    setFormData((prevData) => ({
      ...prevData,
      family: [...prevData.family, { relation: "", name: "", age: "", occupation: "" }],
    }));
  };

  const handleAddHobby = () => {
    if (!newHobby) return; // Don't proceed if newHobby is empty

    const currentHobbies = Array.isArray(formData.hobbies) ? formData.hobbies : [];
    
    if (!currentHobbies.includes(newHobby)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        hobbies: [...currentHobbies, newHobby]
      }));
      setNewHobby("");
    } else {
      alert("This hobby has already been added!");
    }
  };
  
  const handleRemoveHobby = (hobbyToRemove) => {
    setFormData((prevData) => ({
      ...prevData,
      hobbies: prevData.hobbies.filter((hobby) => hobby !== hobbyToRemove),
    }));
  };

  useEffect(() => {
    if (id) {
      instance
        .get(`basic-info/${id}`)
        .then((res) => {
          const basicInfoData = res.data;
          updateFormData(basicInfoData);
          
          // Handle hobbies - ensure it's an array
          if (basicInfoData.hobbies) {
            const hobbiesArray = typeof basicInfoData.hobbies === 'string' 
              ? basicInfoData.hobbies.split(',')
              : basicInfoData.hobbies;
            setFormData(prev => ({
              ...prev,
              hobbies: hobbiesArray
            }));
          }

          // Handle social media links
          if (basicInfoData.social_links) {
            let links;
            try {
              // Handle case where social_links might be a string
              links = typeof basicInfoData.social_links === 'string' 
                ? JSON.parse(basicInfoData.social_links)
                : basicInfoData.social_links;
            } catch (e) {
              links = [];
            }
            setSocialMediaLinks(links);
          }

          if (basicInfoData.family_members) {
            setFamilyDetails(basicInfoData.family_members);
          }
        })
        .catch((error) => {
          console.error("Error fetching basic info:", error);
        });
    } else {
      instance
        .get(`basic-info/`)
        .then((res) => {
          const basicInfoData = res.data;
          updateFormData(basicInfoData);
        })
        .catch((error) => {
          console.error("Error fetching basic info:", error);
        });
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      instance
        .get(`basic-info/${id}`)
        .then((res) => {
          const basicInfoData = res.data;
          
          // Handle hobbies properly
          let hobbiesArray = [];
          if (basicInfoData.hobbies) {
            hobbiesArray = typeof basicInfoData.hobbies === 'string'
              ? basicInfoData.hobbies.split(',').filter(hobby => hobby.trim() !== '')
              : Array.isArray(basicInfoData.hobbies)
                ? basicInfoData.hobbies
                : [];
          }

          // Update form data with processed hobbies
          updateFormData({
            ...basicInfoData,
            hobbies: hobbiesArray
          });

          // Handle other data...
          // ...existing code...
        })
        .catch((error) => {
          console.error("Error fetching basic info:", error);
        });
    }
  }, [id]);

  const handleEditFamilyMember = (id) => {
    const memberToEdit = familyDetails.find((member) => member.id === id);
    if (memberToEdit) {
      setNewMember({
        name: memberToEdit.name,
        age: memberToEdit.age,
        relationship: memberToEdit.relationship,
        occupation: memberToEdit.occupation,
      });
      setFamilyDetails((prev) =>
        prev.map((member) => (member.id === id ? { ...member, isEditing: true } : member))
      );
    }
  };

  const handleSaveNewFamilyMember = (id) => {
    if (isAddingNewMember) {
      const newId = familyDetails.length
        ? Math.max(familyDetails.map((member) => member.id)) + 1
        : 1;
      const newMemberWithId = { ...newMember, id: newId };
      setFamilyDetails((prev) => [...prev, newMemberWithId]);
      instance
        .post(SERVER_BASE_ADDRESS + "family-details/", newMember)
        .then((res) => {
          console.log(res.data);
          setIsAddingNewMember(false);
          setIsSaved(true);
          alert("New Family Member Added Successfully");
        })
        .catch((error) => {
          alert(error);
        });

      setNewMember({ name: "", age: "", relationship: "", occupation: "" });
    } else {
      instance
        .put(`family-details/${id}/`, newMember)
        .then((res) => {
          if (res.data) {
            alert("Data Updated Successfully");
          }
        })
        .catch((err) => {
          console.log("Err", err);
        });
    }
  };
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      social_links: socialMediaLinks
    }));
  }, [socialMediaLinks]);
  
  const handleAddSocialMedia = (name, url) => {
    if (editingSocialMedia !== null) {
      setSocialMediaLinks(prevLinks => 
        prevLinks.map((link, idx) => 
          idx === editingSocialMedia.index ? { name, url } : link
        )
      );
      setEditingSocialMedia(null);
    } else {
      setSocialMediaLinks(prevLinks => [...prevLinks, { name, url }]);
    }
  };

  const handleEditSocialMedia = (index) => {
    const linkToEdit = socialMediaLinks[index];
    setEditingSocialMedia({
      name: linkToEdit.name,
      url: linkToEdit.url,
      index
    });
  };

  const handleRemoveSocialMedia = (index) => {
    setSocialMediaLinks(prevLinks => prevLinks.filter((_, idx) => idx !== index));
  };

  useEffect(() => {
    if (id) {
      instance
        .get(`basic-info/${id}`)
        .then((res) => {
          const basicInfoData = res.data;
          updateFormData(basicInfoData);
          
          // Handle social media links
          if (basicInfoData.social_links) {
            try {
              const links = typeof basicInfoData.social_links === 'string' 
                ? JSON.parse(basicInfoData.social_links)
                : basicInfoData.social_links;
              setSocialMediaLinks(Array.isArray(links) ? links : []);
            } catch (e) {
              console.error('Error parsing social links:', e);
              setSocialMediaLinks([]);
            }
          }
          // ...rest of the existing code...
        })
        .catch((error) => {
          console.error("Error fetching basic info:", error);
        });
    }
  }, [id]);

  const handleDeleteClick = (familyid) => {
    setFamilyId(familyid);
    setOpenDialog(true);
  };
  const handleConfirmationYes = () => {
    instance
      .delete(`family-details/${familyId}/`)
      .then((response) => {
        console.log(response.data);

        alert("Deleted Succesfully");
        setOpenDialog(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setOpenDialog(false);
      });
  };

  const [aadharFile, setAadharFile] = useState(null);
  const [panFile, setPanFile] = useState(null);

  const handleProfilePictureChange = (file) => {
    setProfilePicture(file);
  };

  useEffect(() => {
    if (id) {
      instance.get(`basic-info/${id}`).then((res) => {
        const basicInfoData = res.data;
        
        // Handle hobbies conversion and deduplication
        let hobbies = [];
        if (basicInfoData.hobbies) {
          if (typeof basicInfoData.hobbies === 'string') {
            hobbies = [...new Set(basicInfoData.hobbies.split(',').filter(h => h.trim()))];
          } else if (Array.isArray(basicInfoData.hobbies)) {
            hobbies = [...new Set(basicInfoData.hobbies.filter(Boolean))];
          }
        }

        // Update form data with clean hobbies
        updateFormData({
          ...basicInfoData,
          hobbies: hobbies
        });

        // Handle social media links
        // ...rest of existing code...
      });
    }
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formDataToSend = new FormData();

    // Add basic fields
    formDataToSend.append("first_name", formData.first_name);
    formDataToSend.append("middle_name", formData.middle_name);
    formDataToSend.append("last_name", formData.last_name);
    formDataToSend.append("mobile_number", formData.mobile_number);
    formDataToSend.append("email", formData.email);

    // Handle gender and birth date
    if (formData.gender) formDataToSend.append("gender", formData.gender);
    if (formData.birth_date) formDataToSend.append("birth_date", formData.birth_date);
    if (formData.relationship_status) formDataToSend.append("relationship_status", formData.relationship_status);

    // Handle hobbies - with deduplication
    if (formData.hobbies && Array.isArray(formData.hobbies)) {
      const uniqueHobbies = [...new Set(formData.hobbies)].filter(Boolean);
      if (uniqueHobbies.length > 0) {
        formDataToSend.append("hobbies", uniqueHobbies.join(','));
      }
    }

    // Ensure social media links are properly stringified
    if (socialMediaLinks && socialMediaLinks.length > 0) {
      formDataToSend.append('social_links', JSON.stringify(socialMediaLinks));
    } else {
      formDataToSend.append('social_links', JSON.stringify([]));
    }

    // Handle other fields
    Object.keys(formData).forEach(key => {
      if (formData[key] !== null && 
          formData[key] !== "" && 
          key !== 'social_links' && 
          key !== 'profile_picture' &&
          key !== 'hobbies') {  // Add hobbies to excluded keys
        formDataToSend.append(key, formData[key]);
      }
    });

    // Handle files
    if (aadharFile) {
      formDataToSend.append("aadhar_file", aadharFile);
    }
    if (panFile) {
      formDataToSend.append("pan_file", panFile);
    }

    // Only append profile_picture if it exists and is a file
    if (profilePicture instanceof File) {
      formDataToSend.append("profile_picture", profilePicture);
    }

    // Remove video file if it's null
    if (formData.videoFile instanceof File) {
      formDataToSend.append("videoFile", formData.videoFile);
    }

    // Update the API endpoint based on whether we're editing or creating
    const apiEndpoint = id 
      ? `basic-info/${id}/`
      : 'basic-info/';

    const method = id ? 'put' : 'post';

    instance[method](SERVER_BASE_ADDRESS + apiEndpoint, formDataToSend, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log('Response:', res.data);
      setIsSaved(true);
      alert("Data Saved Successfully");
      setTimeout(() => {
        window.location = "/personal-details-view";
      }, 200);
    })
    .catch((error) => {
      console.error("Error details:", error.response?.data);
      alert(error.response?.data?.message || "An error occurred while saving");
    });
  };

  return (
    <>
      <Sidebar />
      <Container maxWidth="xs" style={{ marginTop: "30px" }}>
        <Container>
          <ProfileHeader 
            showUploadIcon={true} 
            onImageChange={handleProfilePictureChange}
            currentImage={formData.profile_picture}
          />
          <Grid container spacing={2} mt={1}>
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: "600" }} mb={1.5}>
                Personal Information
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "15px" }} pb={1}>
              Name*
            </Typography>
            <TextField
               value={  `${formData.first_name} ${
                formData.middle_name && formData.middle_name !== "null" 
                  ? formData.middle_name + ' ' 
                  : ''
              }${formData.last_name}`}
              onChange={handleInputChange} // Assuming you handle input changes correctly
              placeholder="Name"
              required
              fullWidth
              size="small"
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                borderRadius: "5px",
                height: "45px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                  "& input": {
                    padding: "10px 14px",
                  },
                },
              }}
            />
          </Grid>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <Typography>Gender*</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "-12px" }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}>
                    <FormControlLabel value="M" control={<Radio />} label="Male" />
                    <FormControlLabel value="F" control={<Radio />} label="Female" />
                    <FormControlLabel value="O" control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>
              </Grid>

<Grid item xs={12}>
  <Typography>Date of Birth</Typography>
</Grid>
{formData.birth_date ? (
  <Grid item mt={-1} xs={12}>
    <Box display="flex" fullWidth>
      <TextField
        label="Birth Date"
        sx={{ backgroundColor: "rgba(214,214,214, 0.3)" }}
        value={formData.birth_date}
        onChange={handleBirthDateChange}
        fullWidth
      />
      <IconButton onClick={clearBirthDate} size="small" color="primary">
        <img
          src={EditIcon}
          style={{ width: '24px', height: '24px' }}
          alt="Edit"
        />
      </IconButton>
    </Box>
  </Grid>
) : (
  <Grid item xs={12}>
    <Box fullWidth display="flex" justifyContent="center">
      <DatePicker
        className="custom-date-picker"
        value={formData.birth_date}
        onChange={handleBirthDateChange}
        format="y-MM-dd"
        clearIcon={null}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        calendarIcon={<span>📅</span>}
        maxDate={dayjs().toDate()}
        style={{
          width: "100%",
          backgroundColor: "rgba(214,214,214, 0.3)",
          height: "45px",
        }}
      />
    </Box>
  </Grid>
)}
            </Grid>

            <Grid container spacing={2} mt={0.25}>
              <Grid item xs={12}>
                <Typography style={{ marginTop: "6px" }}>Relationship Status</Typography>
              </Grid>
              <Grid item mt={-1} xs={12}>
                <FormControl fullWidth>
                  <Select
                    id="relationship-input-id"
                    labelId="relationship-input"
                    value={formData.relationship_status}
                    onChange={handleRelationshipChange}
                    variant="standard"
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      "&::before, &::after": { border: "none" },
                      height: "45px",
                      borderRadius: "5px",
                    }}
                    fullWidth>
                    <MenuItem value="S">Single</MenuItem>
                    <MenuItem value="M">Married</MenuItem>
                    <MenuItem value="D">Divorced</MenuItem>
                    <MenuItem value="W">Widowed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.25}>

              <Grid item xs={12}>
                <Typography style={{ marginTop: "6px" }}>Hobbies</Typography>
              </Grid>
              <Grid item mt={-1} xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={hobbiesArray}
                      getOptionLabel={(option) => option}
                      value={newHobby}
                      onChange={(event, newValue) => setNewHobby(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          fullWidth
                          sx={{
                            backgroundColor: "rgba(214,214,214, 0.3)",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                              },
                            },
                            borderRadius: "5px",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddHobby}
                      fullWidth
                      sx={{
                        backgroundColor: "white",
                        minWidth: "30px",
                        minHeight: "35px",
                        color: "#666671",
                        border: "1px solid #666671",
                        textTransform: "none",
                        boxShadow: "none",
                        fontSize: "15px",
                        padding: "10px 0px",
                        "&:hover": {
                          backgroundColor: "white",
                          border: "1px solid #666671",
                          boxShadow: "none",
                        },
                        "&:active": {
                          backgroundColor: "white",
                          border: "1px solid #666671",
                          boxShadow: "none",
                        },
                      }}>
                      + Add Hobby
                    </Button>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    {(Array.isArray(formData.hobbies) ? formData.hobbies : []).map((hobby) => (
                      <Chip
                        key={hobby}
                        label={hobby}
                        onDelete={() => handleRemoveHobby(hobby)}
                        style={{ margin: "0 4px 4px 0" }}
                      />
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


            <Grid container spacing={2} mt={0.25}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: "600" }}>
                  Add Family Details
                </Typography>
                {familyDetails.map((member, index) => (
                  <Grid container spacing={2} key={index} mt={0.25}>
                    {member.isEditing ? (
                      <>
                        <Grid item xs={12}>
                          <Typography pb={1.5} mb={-1}>
                            Family Relationship
                          </Typography>
                          <FormControl fullWidth>
                            <Select
                              labelId={`relation-label-${index}`}
                              label="relation"
                              value={member.relationship}
                              onChange={(event) =>
                                setNewMember((prev) => ({
                                  ...prev,
                                  relationship: event.target.value,
                                }))
                              }
                              sx={{
                                backgroundColor: "rgba(214,214,214, 0.3)",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                height: "45px",
                                borderRadius: "5px",
                              }}>
                              <MenuItem value="Father">Father</MenuItem>
                              <MenuItem value="Mother">Mother</MenuItem>
                              <MenuItem value="Daughter">Daughter</MenuItem>
                              <MenuItem value="Son">Son</MenuItem>
                              <MenuItem value="Brother">brother</MenuItem>
                              <MenuItem value="Sister">Sister</MenuItem>
                              <MenuItem value="Spouse">Spouse</MenuItem>
                              <MenuItem value="SonInLaw">Son in Law</MenuItem>
                              <MenuItem value="DaughterInLaw">Daughter in Law</MenuItem>
                              <MenuItem value="FatherInLaw">Father in Law</MenuItem>
                              <MenuItem value="MotherInLaw">Mother in Law</MenuItem>
                              <MenuItem value="BrotherInLaw">Brother in Law</MenuItem>
                              <MenuItem value="SisterInLaw">Sister in Law</MenuItem>
                              <MenuItem value="Other">other</MenuItem>
                            </Select>
                          </FormControl>
                          <Grid item xs={12}>
                            <Typography pb={1.5} mb={-1}>
                              Name
                            </Typography>
                            <TextField
                              value={newMember.name}
                              // onChange={(event) => handleFamilyChange(index, "name", event.target.value)}
                              onChange={(event) =>
                                setNewMember((prev) => ({ ...prev, name: event.target.value }))
                              }
                              fullWidth
                              placeholder="Name"
                              sx={{
                                backgroundColor: "rgba(214,214,214, 0.3)",
                                borderRadius: "5px",
                                height: "45px",
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    border: "none",
                                  },
                                  "&:hover fieldset": {
                                    border: "none",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "none",
                                  },
                                  "& input": {
                                    padding: "10px 14px",
                                  },
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography pb={1.5} mb={-1}>
                              Age
                            </Typography>
                            <TextField
                              placeholder="Age"
                              value={newMember.age}
                              // onChange={(event) => handleFamilyChange(index, "age", event.target.value)}
                              onChange={(event) =>
                                setNewMember((prev) => ({ ...prev, age: event.target.value }))
                              }
                              fullWidth
                              onKeyPress={(event) => {
                                const charCode = event.charCode;
                                if (!(charCode >= 48 && charCode <= 57)) {
                                  event.preventDefault();
                                }
                              }}
                              sx={{
                                backgroundColor: "rgba(214,214,214, 0.3)",
                                borderRadius: "5px",
                                height: "45px",
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    border: "none",
                                  },
                                  "&:hover fieldset": {
                                    border: "none",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "none",
                                  },
                                  "& input": {
                                    padding: "10px 14px",
                                  },
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl sx={{ width: "100%", display: "flex" }} fullWidth>
                              <Typography pb={1.5} mb={-1}>
                                Occupation
                              </Typography>
                              <Select
                                labelId={`occupation-label`}
                                value={newMember.occupation}
                                label="Occupation"
                                // onChange={(event) =>
                                //   handleFamilyChange(index, "occupation", event.target.value)
                                // }
                                onChange={(event) =>
                                  setNewMember((prev) => ({
                                    ...prev,
                                    occupation: event.target.value,
                                  }))
                                }
                                sx={{
                                  backgroundColor: "rgba(214,214,214, 0.3)",
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                  height: "45px",
                                  borderRadius: "5px",
                                }}>
                                <MenuItem value="Student">Student</MenuItem>
                                <MenuItem value="Employed">Employed</MenuItem>
                                <MenuItem value="Unemployed">Unemployed</MenuItem>
                                <MenuItem value="Self-Employed">Self-Employed</MenuItem>
                                <MenuItem value="Retired">Retired</MenuItem>
                                <MenuItem value="Home Maker">Home Maker</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <CustomButton onClick={() => handleSaveNewFamilyMember(member.id)}>
                            Save
                          </CustomButton>
                        </Grid>
                      </>
                    ) : (
                      // Render member's details
                      <Grid
                        sx={{
                          width: "100%",
                          marginBottom: "20px",
                        }}
                        container>
                        <Grid item xs={10}>
                          <Typography>Relationship: {member.relationship}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          {" "}
                          <IconButton>
                            <img
                              src={EditIcon}
                              style={{ width: "20px", height: "20px", marginRight: "7px" }}
                              onClick={() => handleEditFamilyMember(member.id)}
                              alt="Edit Icon"
                            />
                          </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton>
                            <img
                              src={TrashIcon}
                              style={{ width: "20px", height: "20px", marginRight: "7px" }}
                              onClick={() => handleDeleteClick(member.id)}
                              alt="Delete Icon"
                            />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>Name: {member.name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>Age: {member.age}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>Occupation: {member.occupation}</Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                ))}
                {isAddingNewMember ? (
                  <Grid container spacing={2} mt={0.25}>
                    <Grid item xs={12}>
                      <Typography variant="h6" sx={{ fontWeight: "600" }}>
                        Add New Family Member
                      </Typography>
                    </Grid>
                    {/* Form fields for new member */}
                    <Grid item xs={12}>
                      <Typography pb={1.5} mb={-1}>
                        Family Relationship
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          labelId="new-relation-label"
                          label="relation"
                          value={newMember.relationship}
                          onChange={(event) =>
                            setNewMember((prev) => ({ ...prev, relationship: event.target.value }))
                          }
                          sx={{
                            backgroundColor: "rgba(214,214,214, 0.3)",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            height: "45px",
                            borderRadius: "5px",
                          }}>
                          <MenuItem value="Father">Father</MenuItem>
                          <MenuItem value="Mother">Mother</MenuItem>
                          <MenuItem value="Daughter">Daughter</MenuItem>
                          <MenuItem value="Son">Son</MenuItem>
                          <MenuItem value="Brother">brother</MenuItem>
                          <MenuItem value="Sister">Sister</MenuItem>
                          <MenuItem value="Spouse">Spouse</MenuItem>
                          <MenuItem value="SonInLaw">Son in Law</MenuItem>
                          <MenuItem value="DaughterInLaw">Daughter in Law</MenuItem>
                          <MenuItem value="FatherInLaw">Father in Law</MenuItem>
                          <MenuItem value="MotherInLaw">Mother in Law</MenuItem>
                          <MenuItem value="BrotherInLaw">Brother in Law</MenuItem>
                          <MenuItem value="SisterInLaw">Sister in Law</MenuItem>
                          <MenuItem value="Other">other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography pb={1.5} mb={-1}>
                        Name
                      </Typography>
                      <TextField
                        value={newMember.name}
                        // onChange={(event) => handleFamilyChange(index, "name", event.target.value)}
                        onChange={(event) =>
                          setNewMember((prev) => ({ ...prev, name: event.target.value }))
                        }
                        fullWidth
                        placeholder="Name"
                        sx={{
                          backgroundColor: "rgba(214,214,214, 0.3)",
                          borderRadius: "5px",
                          height: "45px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                            "&:hover fieldset": {
                              border: "none",
                            },
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                            "& input": {
                              padding: "10px 14px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography pb={1.5} mb={-1}>
                        Age
                      </Typography>
                      <TextField
                        placeholder="Age"
                        value={newMember.age}
                        // onChange={(event) => handleFamilyChange(index, "age", event.target.value)}
                        onChange={(event) =>
                          setNewMember((prev) => ({ ...prev, age: event.target.value }))
                        }
                        fullWidth
                        onKeyPress={(event) => {
                          const charCode = event.charCode;
                          if (!(charCode >= 48 && charCode <= 57)) {
                            event.preventDefault();
                          }
                        }}
                        sx={{
                          backgroundColor: "rgba(214,214,214, 0.3)",
                          borderRadius: "5px",
                          height: "45px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                            "&:hover fieldset": {
                              border: "none",
                            },
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                            "& input": {
                              padding: "10px 14px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl sx={{ width: "100%", display: "flex" }} fullWidth>
                        <Typography pb={1.5} mb={-1}>
                          Occupation
                        </Typography>
                        <Select
                          labelId={`occupation-label`}
                          value={newMember.occupation}
                          label="Occupation"
                          // onChange={(event) =>
                          //   handleFamilyChange(index, "occupation", event.target.value)
                          // }
                          onChange={(event) =>
                            setNewMember((prev) => ({ ...prev, occupation: event.target.value }))
                          }
                          sx={{
                            backgroundColor: "rgba(214,214,214, 0.3)",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            height: "45px",
                            borderRadius: "5px",
                          }}>
                          <MenuItem value="Student">Student</MenuItem>
                          <MenuItem value="Employed">Employed</MenuItem>
                          <MenuItem value="Unemployed">Unemployed</MenuItem>
                          <MenuItem value="Self-Employed">Self-Employed</MenuItem>
                          <MenuItem value="Retired">Retired</MenuItem>
                          <MenuItem value="Home Maker">Home Maker</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        onClick={handleSaveNewFamilyMember}
                        sx={{
                          backgroundColor: "#272260",
                          color: "white",
                          textTransform: "none",
                          mt: 1,
                        }}>
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => setIsAddingNewMember(false)}
                        sx={{
                          borderColor: "#272260",
                          color: "#272260",
                          textTransform: "none",
                          mt: 1,
                          ml: 1,
                        }}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12} mt={2}>
                    <Button
                      variant="contained"
                      onClick={() => setIsAddingNewMember(true)}
                      fullWidth
                      sx={{
                        backgroundColor: "white",
                        color: "#666671",
                        border: "1px solid #666671",
                        textTransform: "none",
                        boxShadow: "none",
                        "&:hover": {
                          backgroundColor: "white",
                          borderColor: "#666671",
                          boxShadow: "none",
                        },
                      }}>
                      + Add New
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2} mt={0.25}>
  <Grid item xs={12}>
    <Typography variant="h6" sx={{ fontWeight: "600" }}>
      Social Media Links
    </Typography>
  </Grid>

  <Grid item xs={12} mt={2}>
  {socialMediaLinks.map((link, index) => (
    <Box 
      key={index} 
      display="flex" 
      alignItems="center" 
      mb={2}
      sx={{
        backgroundColor: "rgba(214,214,214, 0.3)",
        borderRadius: "5px",
        padding: "8px 12px",
      }}
    >
      <Tooltip title={`${link.name}: ${link.url}`}>
        <Typography 
          variant="body2" 
          sx={{ 
            flex: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "14px"
          }}
        >
          <strong>{link.name}:</strong> {link.url}
        </Typography>
      </Tooltip>
      <Box sx={{ display: "flex", minWidth: "fit-content" }}>
        <IconButton 
          onClick={() => handleEditSocialMedia(index)}
          size="small"
        >
          <img src={EditIcon} alt="Edit" style={{ width: 16, height: 16 }} />
        </IconButton>
        <IconButton 
          onClick={() => handleRemoveSocialMedia(index)}
          size="small"
        >
          <img src={TrashIcon} alt="Delete" style={{ width: 16, height: 16 }} />
        </IconButton>
      </Box>
    </Box>
  ))}
</Grid>
  <Grid item xs={12} mt={2}>
    <SocialMedialPopup 
      onAdd={handleAddSocialMedia} 
      editingLink={editingSocialMedia}
      onCancel={() => setEditingSocialMedia(null)}
      open={Boolean(editingSocialMedia)}  // Add this prop
    />
  </Grid>
</Grid>
            <Grid item xs={8} mx="auto" my={2}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                sx={{ backgroundColor: "#272260" }}
                nextRoute={!isSaved ? undefined : "/edit-address"}>
                Save
              </Button>
            </Grid>
            <Grid item xs={8} mx="auto" mb={4}>
              <BackButton />
            </Grid>
            <ConfirmDeleteDialog
              open={openDialog}
              handleClose={() => setOpenDialog(false)}
              onDelete={handleConfirmationYes}
              title={"Education Detail"}
            />
          </form>
        </Container>
      </Container>
    </>
  );
}
